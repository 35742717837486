import React, { useEffect } from 'react';
import './PrivacyPolicy.css';
import { Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = ({ howToGetStartedRef }) => {
    const location = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <section className='privacy-policy'>
                <Container>
                    <Row>
                        <div className='heading-holder text-center mb-4'>
                            <h2>Privacy Policy</h2>
                            <p><strong>Effective Date:</strong> 26-OCT-2024</p>
                            <p><strong>Last Updated:</strong> 25-NOV-2024</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>Introduction</h3>
                            <p>At JGM HELPHUB, your privacy is our priority. This Privacy Policy outlines how we collect, use, and protect your information in compliance with the Google Play Store's policies.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>Information We Collect</h3>
                            <p><strong>Personal Information:</strong> Information provided voluntarily through form submissions (e.g., name, email, phone number).</p>
                            <p><strong>Device Information:</strong> Device type, operating system, and unique device identifiers.</p>
                            <p><strong>Usage Data:</strong> Information about how you interact with the app, such as features used and session duration.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>How We Use Your Information</h3>
                            <p>The information collected is used for the following purposes:</p>
                            <ul>
                                <li>To provide, operate, and improve our services.</li>
                                <li>To personalize user experiences and improve functionality.</li>
                                <li>To communicate with users about updates, features, or customer support.</li>
                                <li>For compliance with legal obligations.</li>
                            </ul>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>Cookies and Tracking Technologies</h3>
                            <p>Our app may use cookies or similar technologies to enhance user experience and collect usage data. These are used to:</p>
                            <ul>
                                <li>Analyze app performance and usage trends.</li>
                                <li>Enable personalized content or advertising.</li>
                            </ul>
                            <p>You can manage your cookie preferences through your device settings.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>Third-Party Services</h3>
                            <p>We may share non-personal and aggregated data with trusted third-party service providers for:</p>
                            <ul>
                                <li>Analytics (e.g., Google Analytics).</li>
                                <li>Advertising (e.g., Google AdMob).</li>
                                <li>Social media integrations.</li>
                            </ul>
                            <p>These third parties are obligated to protect your information under their privacy policies.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>User Rights</h3>
                            <p>You have the right to:</p>
                            <ul>
                                <li>Access, correct, or delete your personal data.</li>
                                <li>Withdraw consent for data collection where applicable.</li>
                                <li>Request a copy of the data we hold about you.</li>
                            </ul>
                            <p>To exercise your rights, please contact us at <a href='mailto:jgmhelphub@gmail.com'>jgmhelphub@gmail.com</a>.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>Data Security</h3>
                            <p>We implement robust measures to protect your information against unauthorized access, disclosure, or destruction. However, no method of transmission or storage is 100% secure.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>Children’s Privacy</h3>
                            <p>Our app is not intended for use by children under 13 years of age. We do not knowingly collect personal information from children.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>Changes to This Privacy Policy</h3>
                            <p>We may update this Privacy Policy from time to time. Any changes will be posted here, and we will notify users of significant updates.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h3>Contact Us</h3>
                            <p>If you have any questions or concerns about this Privacy Policy, please contact us:</p>
                            <p>Email: <a href='mailto:jgmhelphub@gmail.com'>jgmhelphub@gmail.com</a></p>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default PrivacyPolicy;
